import React from "react";
//API for data
import {GetData} from "./GetData";
import {PostData} from "./PostData";
//React JS Bootstrap Tags
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

class DonationCauses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            addUpdateDonationCauseModal: false,
            editDonationCause: false,
            donation_cause_id: '',
            donation_cause_title: '',
            donation_cause_titleErr: '',
            deleteDonationCauseModal: false,
            donationCauseData: '',
            donationCauseDataItems: '',
            OpeAlert: true,
            OpeAlertMessage: '',
            OpeAlertVariant: '',
            dataLoader: true,
        }
        this.onChangeCreateDonationCauseFormData = this.onChangeCreateDonationCauseFormData.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.loadAllDonationCause = this.loadAllDonationCause.bind(this)
        this.addUpdateDonationCause = this.addUpdateDonationCause.bind(this)
        this.deleteDonationCause = this.deleteDonationCause.bind(this)
    }

    componentDidMount() {
        this.loadAllDonationCause()
    }

    loadAllDonationCause() {
        GetData('/frontCall/getAllDonationCauses', this.state.userToken)
            .then(result => {
                this.setState({donationCauseData: result.response});
                const listItems = this.state.donationCauseData.map((data) =>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={data.id}>
                        <Card className="overflow-hidden">
                            <Card.Body className="py-3 px-4">
                                <Card.Text className="m-0">{data.title}</Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-right bg-white">
                                <Button variant="primary" className="mb-1 mb-md-0 ml-1 line-height-1"
                                        onClick={() => {
                                            this.setState({
                                                addUpdateDonationCauseModal: true,
                                                editDonationCause: true,
                                                donation_cause_id: data.id,
                                                donation_cause_title: data.title,
                                            })
                                        }}>
                                    <em className="simple-icon-note"></em>
                                </Button>
                                <Button variant="danger" className="mb-1 mb-md-0 ml-1 line-height-1"
                                        onClick={() => {
                                            this.setState({
                                                deleteDonationCauseModal: true,
                                                donation_cause_id: data.id,
                                            })
                                        }}>
                                    <em className="simple-icon-trash"></em>
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>);
                this.setState({donationCauseDataItems: listItems, dataLoader: false,});
            })
    }

    addUpdateDonationCause() {
        this.setState({dataLoader: true,})
        const postDataObj = {
            title: this.state.donation_cause_title
        }
        if (this.state.donation_cause_id) {
            postDataObj.id = this.state.donation_cause_id
        }
        let postApi = this.state.donation_cause_id ? "/frontCall/updateDonationCause" : "/frontCall/createDonationCause";
        PostData(postApi, postDataObj, this.state.userToken)
            .then(result => {
                if (result.response) {
                    this.handleClose()
                    this.setState({
                        OpeAlert: true,
                        OpeAlertMessage: result.response,
                        OpeAlertVariant: 'success',
                        donation_cause_id: '',
                        donation_cause_title: '',
                    })
                    this.loadAllDonationCause()
                } else {
                    this.setState({dataLoader: false})
                    if (result.message.donation_cause_title) {
                        this.setState({donation_cause_titleErr: result.message.title})
                    } else {
                        this.setState({donation_cause_titleErr: result.message.title})
                    }
                }
            })
    }

    deleteDonationCause() {
        this.setState({dataLoader: true,})
        PostData('/frontCall/deleteDonationCause', {donation_cause_id: this.state.donation_cause_id}, this.state.userToken)
            .then(result => {
                this.loadAllDonationCause()
                this.handleClose()
                this.setState({
                    OpeAlert: true,
                    OpeAlertMessage: result.response,
                    OpeAlertVariant: 'success',
                })
            })
    }

    onChangeCreateDonationCauseFormData(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleShow() {
        this.setState({
            addUpdateDonationCauseModal: true,
            editDonationCause: false,
            donation_cause_title: '',
            donation_cause_id: '',
        })
    }

    handleClose() {
        this.setState({
            addUpdateDonationCauseModal: false,
            donation_cause_titleErr: '',
            deleteDonationCauseModal: false,
        })
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Donation Causes</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Donation Causes</li>
                                </ol>
                            </nav>
                            <Button onClick={this.handleShow} variant="primary" className="mb-2 line-height-1">
                                Add Donation Cause
                            </Button>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Modal
                        show={this.state.addUpdateDonationCauseModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>{this.state.editDonationCause ? "Edit" : "Add"} Donation Cause</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="donation_cause_title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name="donation_cause_title" placeholder="Title"
                                              value={this.state.donation_cause_title} onChange={this.onChangeCreateDonationCauseFormData}/>
                                {this.state.donation_cause_titleErr &&
                                    <Form.Text as={"div"} className="text-danger"> {this.state.donation_cause_titleErr} </Form.Text>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.addUpdateDonationCause}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteDonationCauseModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this Donation Cause?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.deleteDonationCause}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                    </Alert>

                    <Row>
                        {this.state.dataLoader ?
                            <div className="api-data-loader">
                                <Spinner animation="grow" variant="info"/>
                            </div> :
                            this.state.donationCauseDataItems
                        }
                    </Row>
                </div>
            </main>
        )
    }
}

export default DonationCauses;