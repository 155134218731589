import React from "react";
//API for data
import {GetData} from "./GetData";
//Datatable Files
import Datatable from "react-data-table-component";
import "./css/dataTable.css"
//React JS Bootstrap Tags
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table"
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import {PostData} from "./PostData";
import Alert from "react-bootstrap/Alert";


class Donations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            donationsData: [],
            columns: [],
            dataLoader: true,
            OpeAlert: false,
            OpeAlertMessage: '',
            OpeAlertVariant: '',
        }
        this.loadAllDonations = this.loadAllDonations.bind(this)
        this.handleDeleteDonation = this.handleDeleteDonation.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.loadAllDonations()
    }

    loadAllDonations() {
        GetData('/frontCall/getAllDonations', this.state.userToken)
            .then(result => {
                this.setState({
                    donationsData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">Full Name</h6>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Email</h6>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Amount</h6>,
                            selector: (row) => row.amount,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Type</h6>,
                            selector: (row) => row.payment_type,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Status</h6>,
                            selector: (row) => row.payment_status,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Date</h6>,
                            selector: (row) => row.payment_date,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Gift Aid Support</h6>,
                            selector: (row) => row.gift_aid_support,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Created</h6>,
                            selector: (row) => row.created,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Donation Cause</h6>,
                            selector: (row) => row.title,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Action</h6>,
                            cell: (row) => (
                                <Button variant="outline-danger" size={"sm"} className="line-height-1"
                                        onClick={() => {
                                            this.setState({
                                                donation_id: row.id,
                                                showDeleteModal: true,
                                            })
                                        }}>Delete</Button>
                            )
                        },
                    ],
                    dataLoader: false,
                });
            });
    }

    handleDeleteDonation() {
        this.setState({dataLoader: true})
        PostData("/frontCall/deleteDonations", {donation_id: this.state.donation_id}, this.state.userToken)
            .then((result) => {
                this.setState({
                    OpeAlert: true,
                    OpeAlertMessage: result.response,
                    OpeAlertVariant: 'success',
                    showDeleteModal: false,
                })
                this.loadAllDonations()
            })
    }

    handleClose() {
        this.setState({
            showDeleteModal: false,
        })
    }

    render() {
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Donations</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Donations</li>
                                </ol>
                            </nav>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                    </Alert>

                    <Modal
                        show={this.state.showDeleteModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this Donation?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleDeleteDonation}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    {this.state.dataLoader ?
                                        <div className="api-data-loader">
                                            <Spinner animation="grow" variant="info"/>
                                        </div> :
                                        <Datatable
                                            columns={this.state.columns}
                                            data={this.state.donationsData}
                                            pagination
                                            highlightOnHover
                                            subHeader
                                        />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        )
    }
}

export default Donations;