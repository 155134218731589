import React from "react";
import {BrowserRouter, Switch, Route, Redirect, Link} from "react-router-dom";
import App from "../App";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <BrowserRouter>
                    <Redirect to='/' />
                    <Switch>
                        <Route path='/'>
                            <App/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        } else {
            return (
                <nav className="navbar fixed-top flex-nowrap">
                    <div className="d-flex align-items-center navbar-left">
                        <Button variant="link" className="menu-button d-none d-md-block p-0" onClick={()=>{
                            document.getElementById("app-container").classList.toggle("main-hidden")
                        }}>
                            <svg className="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                                <rect x="0.48" y="0.5" width="7" height="1" />
                                <rect x="0.48" y="7.5" width="7" height="1" />
                                <rect x="0.48" y="15.5" width="7" height="1" />
                            </svg>
                            <svg className="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                                <rect x="1.56" y="0.5" width="16" height="1" />
                                <rect x="1.56" y="7.5" width="16" height="1" />
                                <rect x="1.56" y="15.5" width="16" height="1" />
                            </svg>
                        </Button>
                        <Button variant="link" className="menu-button d-xs-block d-sm-block d-md-none p-0" onClick={()=>{
                            document.getElementById("app-container").classList.toggle("main-hidden")
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                                <rect x="0.5" y="0.5" width="25" height="1" />
                                <rect x="0.5" y="7.5" width="25" height="1" />
                                <rect x="0.5" y="15.5" width="25" height="1" />
                            </svg>
                        </Button>
                    </div>

                    <Link className="navbar-logo" to="/dashboard">
                        <span className="logo d-none d-xs-block"></span>
                        <span className="logo-mobile d-block d-xs-none"></span>
                    </Link>

                    <div className="navbar-right">
                        <Dropdown>
                            <Dropdown.Toggle variant="">
                                <span className="name d-inline-block">{this.props.fullName}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={"button"}  onClick={this.props.userLogout}>Sign out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </nav>
            )
        }
    }
}

export default Header;