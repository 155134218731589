import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer">
                <div className="footer-content">
                    <div className="container-fluid">
                        <p className="mb-0 text-muted">Salaam Centre 2022</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;