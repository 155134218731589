import React from 'react';
import ImportEventSalah from "./ImportEventSalah";
//API for data
import {GetData} from "./GetData";
import {PostData} from "./PostData";
//Datatable Files
import Datatable from 'react-data-table-component';
import './css/dataTable.css';
//Moment JS
import moment from "moment";
//React JS Bootstrap Tags
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from "react-bootstrap/Spinner";

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            eventData: [],
            columns: [],
            filteredEventsData: [],
            event_id: '',
            event_title: '',
            event_titleErr: '',
            event_date: '',
            event_dateErr: '',
            showCreateEventModal: false,
            editEventModal: false,
            showDeleteEventModal: false,
            importModal: false,
            deleteEventId: '',
            eventOpeAlert: false,
            eventOpeAlertMessage: '',
            eventOpeAlertVariant: '',
            dataLoader: true,
        }
        this.setSearch = this.setSearch.bind(this)
        this.addUpdateEvent = this.addUpdateEvent.bind(this)
        this.onChangeCreateEventFormData = this.onChangeCreateEventFormData.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleImportModalShow = this.handleImportModalShow.bind(this)
        this.handleImportModalClose = this.handleImportModalClose.bind(this)
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this)
    }

    componentDidMount() {
        this.loadAllEvents();
    }

    loadAllEvents() {
        GetData('/frontCall/getAllEvents', this.state.userToken)
            .then(result => {
                this.setState({
                    eventData: result.response,
                    filteredEventsData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">Event Title</h6>,
                            selector: (row) => row.event_title,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Event Date</h6>,
                            selector: "event_date",
                            sortable: true,
                            cell: (row) => moment(row.event_date).format("DD/MM/YYYY"),
                        },
                        {
                            name: <h6 className="font-weight-bold">Action</h6>,
                            cell: (row) => (
                                <span className="d-flex justify-content-center">
                                    <Button variant="primary" onClick={() => {
                                        this.setState({
                                            event_id: row.id,
                                            event_title: row.event_title,
                                            event_date: row.event_date.split('/').reverse().join('-'),
                                            showCreateEventModal: true,
                                            editEventModal: true,
                                        })
                                    }}>
                                        <em className="simple-icon-note"></em>
                                    </Button>
                                    <Button variant="danger" className="ml-2" onClick={() => {
                                        this.setState({
                                            showDeleteEventModal: true,
                                            deleteEventId: row.id
                                        })
                                    }}>
                                        <em className="simple-icon-trash"></em>
                                    </Button>
                                </span>
                            )
                        },
                    ],
                    dataLoader: false,
                });
            });
    }

    setSearch(e) {
        const result = this.state.eventData.filter(event => {
            return event.event_title.toLowerCase().match(e.target.value.toLowerCase()) ||
                event.event_date.toLowerCase().match(e.target.value.toLowerCase());
        });
        this.setState({filteredEventsData: result});
    }

    addUpdateEvent() {
        this.setState({dataLoader: true})
        let postDataObj = {
            event_title: this.state.event_title,
            event_date: this.state.event_date
        };
        if (this.state.event_id) {
            postDataObj.id = this.state.event_id;
        }
        let postApi = this.state.event_id ? "/frontCall/updateEvent" : "/frontCall/createEvent";
        PostData(postApi, postDataObj, this.state.userToken)
            .then(result => {
                if (result.status) {
                    this.handleClose()
                    this.setState({
                        eventOpeAlert: true,
                        eventOpeAlertMessage: result.response,
                        eventOpeAlertVariant: 'success',
                        event_title: '',
                        event_date: '',
                        event_id: '',
                    })
                    this.loadAllEvents();
                } else {
                    this.setState({dataLoader: false})
                    if (result.message.event_title) {
                        this.setState({event_titleErr: result.message.event_title});
                    } else {
                        this.setState({event_titleErr: ''});
                    }
                    if (result.message.event_date) {
                        this.setState({event_dateErr: result.message.event_date});
                    } else {
                        this.setState({event_dateErr: ''});
                    }
                }
            })

    }

    onChangeCreateEventFormData(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleClose() {
        this.setState({
            showCreateEventModal: false,
            showDeleteEventModal: false,
            event_titleErr: '',
            event_dateErr: '',
        });
    }

    handleImportModalShow() {
        this.setState({
            importModal: true,
            eventUploadAlert: false,
            eventUploadAlertMessage: '',
            eventUploadAlertVariant: '',
        })
    }

    handleImportModalClose() {
        this.setState({
            importModal: false,
            dataLoader: false,
        })
        this.loadAllEvents()
    }

    handleShow() {
        this.setState({
            showCreateEventModal: true,
            editEventModal: false,
            event_id: '',
            event_title: '',
            event_date: ''
        })
    }

    handleDeleteEvent() {
        this.setState({dataLoader: true})
        PostData("/frontCall/deleteEvent", {event_id: this.state.deleteEventId}, this.state.userToken)
            .then(result => {
                this.setState({
                    eventOpeAlert: true,
                    eventOpeAlertMessage: result.response,
                    eventOpeAlertVariant: 'success',
                })
            })
        this.setState({
            showDeleteEventModal: false,
        });
        this.loadAllEvents()
    }

    render() {
        document.title = this.props.title;

        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Events</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Events</li>
                                </ol>
                            </nav>
                            <Button onClick={this.handleShow} className="mb-2 line-height-1">Add New Event</Button>
                            <Button onClick={this.handleImportModalShow} className="mb-2 ml-2 line-height-1">Import New Event</Button>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Modal
                        show={this.state.showCreateEventModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>{this.state.editEventModal ? "Edit" : "Add New"} Event</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="event_title">
                                <Form.Label>Event Title</Form.Label>
                                <Form.Control type="text" name="event_title" placeholder="Event Title"
                                              value={this.state.event_title} onChange={this.onChangeCreateEventFormData}/>
                                {this.state.event_titleErr && <Form.Text as={"div"} className="text-danger"> {this.state.event_titleErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="event_date">
                                <Form.Label>Event Date</Form.Label>
                                <Form.Control type="date" name="event_date" placeholder="Event Date"
                                              value={this.state.event_date} onChange={this.onChangeCreateEventFormData}/>
                                {this.state.event_dateErr && <Form.Text as={"div"} className="text-danger"> {this.state.event_dateErr} </Form.Text>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.addUpdateEvent}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.showDeleteEventModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this Event?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleDeleteEvent}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <ImportEventSalah closeImport={this.handleImportModalClose} importModal={this.state.importModal} />

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Alert show={this.state.eventOpeAlert} variant={this.state.eventOpeAlertVariant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.eventOpeAlertMessage}</h6>
                                    </Alert>
                                    {this.state.dataLoader ?
                                        <div className="api-data-loader">
                                            <Spinner animation="grow" variant="info"/>
                                        </div> :
                                        <Datatable
                                            columns={this.state.columns}
                                            data={this.state.filteredEventsData}
                                            pagination
                                            highlightOnHover
                                            defaultSortFieldId="eventDate"
                                            subHeader
                                            subHeaderComponent={
                                                <div className="datatable-search-data">
                                                    <input type="text" placeholder="Search..." className="form-control"
                                                           value={this.state.search} onChange={this.setSearch}/>
                                                    <span className="datatable-search-icon">
                                                        <i className="simple-icon-magnifier"></i>
                                                    </span>
                                                </div>
                                            }
                                        />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        );
    }
}

export default Events;