import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {GetData} from "./GetData";
import {PostData} from "./PostData";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

class NewsUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            addNewsUpdateModal: false,
            news_update_id: '',
            news_update_image: '',
            news_update_title: '',
            news_update_titleErr: '',
            news_update_description: '',
            news_update_descriptionErr: '',
            newsDataItems: '',
            dataLoader: true,
            OpeAlert: false,
            OpeAlertMessage: '',
            OpeAlertVariant: '',
        }
        this.onChangeCreateNewsFormData = this.onChangeCreateNewsFormData.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.loadAllNews = this.loadAllNews.bind(this)
        this.addNewsUpdate = this.addNewsUpdate.bind(this)
        this.newsImageFileObject = this.newsImageFileObject.bind(this)
        this.handleDeleteNews = this.handleDeleteNews.bind(this)
    }

    componentDidMount() {
        this.loadAllNews()
    }

    loadAllNews() {
        GetData("/frontCall/getAllNews", this.state.userToken)
            .then(result => {
                this.setState({newsData: result.response});
                const listItems = this.state.newsData.map((data) =>
                    <Col xs={12} md={6} lg={4} xl={3} className="mb-3" key={data.id}>
                        <Card className="overflow-hidden">
                            <Card.Img className="news-update-images" src={data.file_path ? data.file_path : '/NewsImages/defaultImage.png'} alt=""/>
                            <Card.Body className="p-3">
                                <Card.Title className="mb-2">{data.title}</Card.Title>
                                <Card.Text>{data.description}</Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-right bg-white p-3">
                                <Button variant="outline-danger" size={"sm"} className="line-height-1"
                                        onClick={() => {
                                            this.setState({
                                                news_update_id: data.id,
                                                showDeleteNewsModal: true,
                                            })
                                        }}>Delete</Button>
                            </Card.Footer>
                        </Card>
                    </Col>)
                this.setState({newsDataItems: listItems, dataLoader: false});
            })
    }

    addNewsUpdate() {
        this.setState({dataLoader: true})
        const fd = new FormData();
        fd.append("file_path", this.state.news_update_image);
        fd.append("title", this.state.news_update_title);
        fd.append("description", this.state.news_update_description);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Authorization': this.state.userToken
            },
            body: fd
        };
        fetch("https://salamadmin.preregmaster.com/salam_admin/frontCall/createNews", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.response) {
                    this.setState({
                        OpeAlert: true,
                        OpeAlertMessage: result.response,
                        OpeAlertVariant: 'success',
                        addNewsUpdateModal: false,
                    })
                    this.loadAllNews()
                } else {
                    this.setState({dataLoader: false})
                    if (result.message.title) {
                        this.setState({news_update_titleErr: result.message.title})
                    } else {
                        this.setState({news_update_titleErr: ''})
                    }
                    if (result.message.description) {
                        this.setState({news_update_descriptionErr: result.message.description})
                    } else {
                        this.setState({news_update_descriptionErr: ''})
                    }
                }
            })
    }

    handleDeleteNews() {
        this.setState({dataLoader: true})
        PostData("/frontCall/deleteNews", {news_id: this.state.news_update_id}, this.state.userToken)
            .then((result) => {
                this.setState({
                    OpeAlert: true,
                    OpeAlertMessage: result.response,
                    OpeAlertVariant: 'success',
                    showDeleteNewsModal: false,
                })
                this.loadAllNews()
            })
    }

    onChangeCreateNewsFormData(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    newsImageFileObject(e) {
        this.setState({news_update_image: e.target.files[0]})
    }

    handleShow() {
        this.setState({
            addNewsUpdateModal: true,
            news_update_image: '',
            news_update_title: '',
            news_update_titleErr: '',
            news_update_description: '',
            news_update_descriptionErr: '',
        })
    }

    handleClose() {
        this.setState({
            addNewsUpdateModal: false,
            showDeleteNewsModal: false,
        })
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>News Updates</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">News Updates</li>
                                </ol>
                            </nav>
                            <Button variant="primary" className="mb-2 line-height-1 ml-2" onClick={this.handleShow}>Add New News</Button>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Modal
                        show={this.state.addNewsUpdateModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Add New News</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="news_update_image">
                                <Form.Label>Upload News Image</Form.Label>
                                <Form.Control type="file" name="news_update_image" placeholder="Upload News Image"
                                              onChange={this.newsImageFileObject}/>
                            </Form.Group>
                            <Form.Group className="form-group" controlId="news_update_title">
                                <Form.Label>News Title</Form.Label>
                                <Form.Control type="text" name="news_update_title" placeholder="News Title"
                                              value={this.state.news_update_title} onChange={this.onChangeCreateNewsFormData}/>
                                {this.state.news_update_titleErr &&
                                    <Form.Text as={"div"} className="text-danger"> {this.state.news_update_titleErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="news_update_description">
                                <Form.Label>News Description</Form.Label>
                                <Form.Control as={"textarea"} rows={4} name="news_update_description" placeholder="News Description"
                                              value={this.state.news_update_description} onChange={this.onChangeCreateNewsFormData}/>
                                {this.state.news_update_descriptionErr &&
                                    <Form.Text as={"div"} className="text-danger"> {this.state.news_update_descriptionErr} </Form.Text>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.addNewsUpdate}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.showDeleteNewsModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this News?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleDeleteNews}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                    </Alert>

                    <Row>
                        {this.state.dataLoader ?
                            <div className="api-data-loader">
                                <Spinner animation="grow" variant="info"/>
                            </div> :
                            this.state.newsDataItems
                        }
                    </Row>
                </div>
            </main>
        )
    }
}

export default NewsUpdates;