import React from "react";
import 'jquery'
import 'Datepicker.js'
import "@fontsource/pt-sans";
import 'bootstrap-float-label/dist/bootstrap-float-label.min.css'

//Router DOM Objects
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";

//Template Components
import Header from "./Template/Header";
import Sidebar from "./Template/Sidebar";
import Footer from "./Template/Footer";

//Main Components
import Login from "./AdminComponents/Login";
import Users from "./AdminComponents/Users";
import Dashboard from "./AdminComponents/Dashboard";
import PrayerTimes from "./AdminComponents/PrayerTimes";
import NewsUpdates from "./AdminComponents/NewsUpdates";
import Donations from "./AdminComponents/Donations";
import DonationCauses from "./AdminComponents/DonationCauses";
import Events from "./AdminComponents/Events";
import Administrators from "./AdminComponents/Administrators";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            fullName: "",
            token: "",
            email: "",
            id: "",
            redirect: false,
            administratorsData: '',
            administratorsDataItems: "",
        };
        this.userLogout = this.userLogout.bind(this)
        let bodyTag = document.querySelector("body")
        bodyTag.classList.remove("no-footer")
        bodyTag.classList.remove("background")
    }

    componentDidMount() {
        /*session not exists then redirect to login page*/
        if (!sessionStorage.getItem("userData")) {
            this.setState({redirect: true});
        } else {
            /*session exists then store data in state*/
            let sessionData = JSON.parse(sessionStorage.getItem('userData'));
            this.setState({fullName: sessionData[0].full_name, token: sessionData[0].token, email: sessionData[0].email, id: sessionData[0].id});
        }

        if(window.innerWidth < 768) {
            document.getElementById("app-container").classList.add("main-hidden")
        }
    }

    userLogout() {
        sessionStorage.setItem("userData", "");
        sessionStorage.clear();
        this.setState({redirect: true});
    }

    render() {
        if (this.state.redirect) {
            return <BrowserRouter>
                <Redirect to="/login"/>
                <Switch>
                    <Route exact path="/login">
                        <Login title="Login - Salam Centre" />
                    </Route>
                </Switch>
            </BrowserRouter>
        } else {
            return <div id="app-container" className="menu-default sub-hidden">
                <BrowserRouter>
                    <Header fullName={this.state.fullName} userLogout={this.userLogout} />
                    <Sidebar/>
                    {window.location.pathname === '/' || window.location.pathname === '' || window.location.pathname === '/login' ? <Redirect userToken={this.state.token} to='/dashboard' /> : <Redirect userToken={this.state.token} to={ window.location.pathname } /> }
                    <Footer />
                    <Switch>
                        <Route exact path="/dashboard">
                            <Dashboard userToken={this.state.token} title="Dashboard - Salam Centre" />
                        </Route>
                        <Route path="/users">
                            <Users userToken={this.state.token} title="Users - Salam Centre" />
                        </Route>
                        <Route path="/prayer_times">
                            <PrayerTimes userToken={this.state.token} title="Prayer Times - Salam Centre" />
                        </Route>
                        <Route path="/news_updates">
                            <NewsUpdates userToken={this.state.token} title="News Updates - Salam Centre" />
                        </Route>
                        <Route path="/donations">
                            <Donations userToken={this.state.token} title="Donations - Salam Centre" />
                        </Route>
                        <Route path="/donation_causes">
                            <DonationCauses userToken={this.state.token} title="Donation Causes - Salam Centre" />
                        </Route>
                        <Route path="/events">
                            <Events userToken={this.state.token} title="Events - Salam Centre" />
                        </Route>
                        <Route path="/administrators">
                            <Administrators userToken={this.state.token} title="Administrators - Salam Centre" />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        }
    }
}

export default App;