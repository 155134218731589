import React from "react";
import {Link} from "react-router-dom";

//React JS Bootstrap Tags
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {GetData} from "./GetData";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            totalUsers: 0,
            totalEvents: 0,
            totalDonations: 0,
            totalNews: 0,
            dataLoader: true,
        }
        this.getTotalUsers = this.getTotalUsers.bind(this)
        this.getTotalEvents = this.getTotalEvents.bind(this)
        this.getTotalNews = this.getTotalNews.bind(this)
        this.getTotalDonations = this.getTotalDonations.bind(this)
    }

    componentDidMount() {
        this.getTotalUsers()
        this.getTotalEvents()
        this.getTotalNews()
        this.getTotalDonations()
    }

    getTotalUsers() {
        GetData("/frontCall/getTotalUsers", this.state.userToken)
            .then(result => {
                this.setState({totalUsers: result.response})
            })
    }

    getTotalEvents() {
        GetData("/frontCall/getTotalEvents", this.state.userToken)
            .then(result => {
                this.setState({totalEvents: result.response})
            })
    }

    getTotalNews() {
        GetData("/frontCall/getTotalNews", this.state.userToken)
            .then(result => {
                this.setState({totalNews: result.response})
            })
    }

    getTotalDonations() {
        GetData("/frontCall/getTotalDonations", this.state.userToken)
            .then(result => {
                this.setState({totalDonations: result.response})
            })
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Dashboard</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item active" aria-current="page">Home</li>
                                </ol>
                            </nav>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
                            <Link to="/users">
                                <Card className="mb-4 progress-banner">
                                    <Card.Body className="d-flex flex-column justify-content-between">
                                        <i className="simple-icon-people mr-2 text-white align-text-bottom d-inline-block"></i>
                                        <Card.Subtitle as={"div"}>
                                            <p className="lead text-white">{this.state.totalUsers} Users</p>
                                            <p className="text-small text-white">Total Users</p>
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
                            <Link to="/donations">
                                <Card className="mb-4 progress-banner">
                                    <Card.Body className="d-flex flex-column justify-content-between">
                                        <i className="iconsminds-financial mr-2 text-white align-text-bottom d-inline-block"></i>
                                        <Card.Subtitle as={"div"}>
                                            <p className="lead text-white">{this.state.totalDonations} Donations</p>
                                            <p className="text-small text-white">Total Donations</p>
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
                            <Link to="/events">
                                <Card className="mb-4 progress-banner">
                                    <Card.Body className="d-flex flex-column justify-content-between">
                                        <i className="simple-icon-calendar mr-2 text-white align-text-bottom d-inline-block"></i>
                                        <Card.Subtitle as={"div"}>
                                            <p className="lead text-white">{this.state.totalEvents} Events</p>
                                            <p className="text-small text-white">Total Events</p>
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
                            <Link to="/news_updates">
                                <Card className="mb-4 progress-banner">
                                    <Card.Body className="d-flex flex-column justify-content-between">
                                        <i className="iconsminds-newspaper mr-2 text-white align-text-bottom d-inline-block"></i>
                                        <Card.Subtitle as={"div"}>
                                            <p className="lead text-white">{this.state.totalNews} News</p>
                                            <p className="text-small text-white">News Updates</p>
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </main>
        )
    }
}

export default Dashboard;