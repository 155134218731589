import React from "react";
import Pagination from "./Pagination";
import './main.css'
//API for data
import {GetData} from "./GetData";
//React JS Bootstrap Tags
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner"
import Form from "react-bootstrap/Form";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            usersData: '',
            filteredUsersData: [],
            usersDataItems: '',
            dataLoader: true,

            usersPerPage: 12,
            currentPageNum: 1,
        }
        this.loadAllUsers = this.loadAllUsers.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.onChangeSearchUserData = this.onChangeSearchUserData.bind(this)
    }

    componentDidMount() {
        this.loadAllUsers()
    }

    loadAllUsers() {
        GetData('/frontCall/getAllUsers', this.state.userToken)
            .then(result => {
                this.setState({usersData: result.response, filteredUsersData: result.response});
                const lastPostIndex = this.state.currentPageNum * this.state.usersPerPage;
                const firstPostIndex = lastPostIndex - this.state.usersPerPage;
                const currentPosts = this.state.usersData.slice(firstPostIndex, lastPostIndex);
                const listItems = currentPosts.map((data) =>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={data.id}>
                        <Card className="overflow-hidden">
                            <Card.Body className="d-flex flex-row justify-content-between align-items-center px-4 py-3">
                                <div className="flex-fill">
                                    <p className="font-weight-medium mb-0">{data.full_name}</p>
                                    <p className="text-muted mb-0 text-small">{data.home_address} - {data.post_code}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>);
                this.setState({usersDataItems: listItems, dataLoader: false});
            });
    }

    onChangeSearchUserData(e) {
        const result = this.state.filteredUsersData.filter(searchSingleUser => {
            return searchSingleUser.full_name.toLowerCase().match(e.target.value.toLowerCase())
        });
        this.setState({usersData: result});
        const lastPostIndex = this.state.currentPageNum * this.state.usersPerPage;
        const firstPostIndex = lastPostIndex - this.state.usersPerPage;
        const currentPosts = result.slice(firstPostIndex, lastPostIndex);
        const listItems = currentPosts.map((data) =>
            <Col xs={12} md={6} xl={4} className="mb-3" key={data.id}>
                <Card className="overflow-hidden">
                    <Card.Body className="d-flex flex-row justify-content-between align-items-center px-4 py-3">
                        <div className="flex-fill">
                            <p className="font-weight-medium mb-0">{data.full_name}</p>
                            <p className="text-muted mb-0 text-small">{data.home_address} - {data.post_code}</p>
                        </div>
                    </Card.Body>
                </Card>
            </Col>);
        this.setState({usersDataItems: listItems, dataLoader: false});
    }

    handlePageChange(e) {
        this.setState({
            currentPage: e.target.value,
            currentPageNum: e.target.value,
        })
        this.loadAllUsers()
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Users</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Card className="overflow-hidden mb-3">
                        <Card.Body className="p-0">
                            <Form.Group className="form-group m-0 px-3" controlId="search_user">
                                <Form.Control type="text" name="userSearch" placeholder="Search User..."
                                              className="border-0" onChange={this.onChangeSearchUserData}/>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <Row>
                        {this.state.dataLoader ?
                            <div className="api-data-loader">
                                <Spinner animation="grow" variant="info"/>
                            </div> :
                            this.state.usersDataItems
                        }
                    </Row>
                    <Pagination
                        pageButtons={this.state.usersData.length}
                        totalUsers={this.state.filteredUsersData.length}
                        usersPerPage={this.state.usersPerPage}
                        currentPageNum={this.state.currentPageNum}
                        currentPageFun={this.handlePageChange}
                    />
                </div>
            </main>
        )
    }
}

export default Users;