import React from 'react';
//DropZone
import Dropzone from 'react-dropzone'
//Demo Import File
import DemoSalah from './demo_salaah_time.xlsx'
//React JS Bootstrap Tags
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/Spinner";

class ImportEventSalah extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            eventUploadAlert: false,
            eventUploadAlertMessage: '',
            eventUploadAlertVariant: '',
            accepted: [],
            rejected: [],
            dataLoader: false,
        }
        this.handleUploadExcelFile = this.handleUploadExcelFile.bind(this)
        this.handleCancelImportFile = this.handleCancelImportFile.bind(this)
    }

    onDrop = (accepted, rejected) => {
        this.setState({
            accepted: accepted,
            rejected: rejected,
            uploadFileContent: accepted[0],
        });
    }

    handleCancelImportFile() {
        this.setState({
            eventUploadAlert: false,
            eventUploadAlertMessage: '',
            eventUploadAlertVariant: '',
            accepted: [],
            rejected: [],
            dataLoader: false,
        })
        this.props.closeImport()
    }

    handleUploadExcelFile() {
        this.setState({dataLoader: true})
        const fd = new FormData();
        fd.append("file", this.state.uploadFileContent);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Authorization': this.state.userToken
            },
            body: fd
        };
        fetch("https://salamadmin.preregmaster.com/salam_admin/frontCall/uploadEvents", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.status) {
                    this.setState({
                        dataLoader: false,
                        eventUploadAlert: true,
                        eventUploadAlertMessage: result.message,
                        eventUploadAlertVariant: 'danger',
                    })
                } else {
                    this.setState({
                        uploadFileContent: undefined,
                    })
                    this.handleCancelImportFile()
                }
            })
    }

    render() {
        return (
            <>
                {this.state.dataLoader ?
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div> :
                    <Modal
                        show={this.props.importModal}
                        onHide={this.props.closeImport}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Import Excel file here</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="w-100">
                                <Alert show={this.state.eventUploadAlert} variant={this.state.eventUploadAlertVariant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.eventUploadAlertMessage}</h6>
                                </Alert>
                                <Form name="uploadEventForm" id="uploadEventForm">
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        multiple={false}
                                    >
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} name="fileUpload" id="fileUpload"/>
                                                {isDragActive ? "Drop here!" : 'Click me or drag a file to upload!'}
                                            </div>
                                        )}
                                    </Dropzone>
                                </Form>

                                {this.state.accepted.length > 0 && <p className="mb-2 mt-3 font-weight-bold">Accepted files</p>}
                                <ul>
                                    {this.state.accepted.map((f) => (
                                        <li key={f.name}>
                                            {f.name} - {f.size} bytes
                                        </li>
                                    ))}
                                </ul>

                                <Button variant={"primary"} className="line-height-1 d-block w-100" onClick={() => {
                                    const element = document.createElement("a");
                                    element.href = DemoSalah
                                    element.download = "salaah_time_sample.xlsx";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}>
                                    Download Sample upload template <i className="simple-icon-cloud-download"></i>
                                </Button>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleUploadExcelFile}>Import</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleCancelImportFile}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

export default ImportEventSalah;