import React from "react";
import ImportEventSalah from "./ImportEventSalah";
//API for data
import {GetData} from "./GetData";
import {PostData} from "./PostData";
//Datatable Files
import Datatable from "react-data-table-component";
import "./css/dataTable.css"
//React JS Bootstrap Tags
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from "react-bootstrap/Spinner";

class PrayerTimes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            prayerTimesData: [],
            columns: [],
            filteredPrayerTimesData: [],
            prayerTimeId: '',
            prayerTimeDate: '',
            prayerTimeDateErr: '',
            prayerTimeImsak: '',
            prayerTimeImsakErr: '',
            prayerTimeFajr: '',
            prayerTimeFajrErr: '',
            prayerTimeSunrise: '',
            prayerTimeSunriseErr: '',
            prayerTimeDhuhr: '',
            prayerTimeDhuhrErr: '',
            prayerTimeAsr: '',
            prayerTimeAsrErr: '',
            prayerTimeMaghrib: '',
            prayerTimeMaghribErr: '',
            prayerTimeIsha: '',
            prayerTimeIshaErr: '',
            importModal: false,
            OpeAlert: false,
            OpeAlertVariant: "",
            OpeAlertMessage: "",
            showEditPrayerTimesModal: false,
            dataLoader: true,
        }
        this.setSearch = this.setSearch.bind(this)
        this.loadAllPrayerTimes = this.loadAllPrayerTimes.bind(this)
        this.onChangeEditPrayerTime = this.onChangeEditPrayerTime.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleImportModalShow = this.handleImportModalShow.bind(this)
        this.handleImportModalClose = this.handleImportModalClose.bind(this)
        this.updatePrayerTime = this.updatePrayerTime.bind(this)
    }

    componentDidMount() {
        this.loadAllPrayerTimes()
    }

    loadAllPrayerTimes() {
        GetData('/frontCall/getAllSalahTimings', this.state.userToken)
            .then(result => {
                this.setState({
                    prayerTimesData: result.response,
                    filteredPrayerTimesData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">Date</h6>,
                            selector: (row) => row.date,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Imsak</h6>,
                            selector: (row) => row.imsak,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Fajr</h6>,
                            selector: (row) => row.fajr,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Sunrise</h6>,
                            selector: (row) => row.sunrise,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Dhuhr</h6>,
                            selector: (row) => row.dhuhr,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Asr</h6>,
                            selector: (row) => row.asr,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Maghrib</h6>,
                            selector: (row) => row.maghrib,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Isha</h6>,
                            selector: (row) => row.isha,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Action</h6>,
                            cell: (row) => (
                                <span className="d-flex justify-content-center">
                                    <Button variant="primary" onClick={() => {
                                        this.setState({
                                            prayerTimeId: row.id,
                                            prayerTimeDate: row.date.split('/').reverse().join('-'),
                                            prayerTimeImsak: row.imsak,
                                            prayerTimeFajr: row.fajr,
                                            prayerTimeSunrise: row.sunrise,
                                            prayerTimeDhuhr: row.dhuhr,
                                            prayerTimeAsr: row.asr,
                                            prayerTimeMaghrib: row.maghrib,
                                            prayerTimeIsha: row.isha,
                                            showEditPrayerTimesModal: true,
                                        })
                                    }}>
                                        <em className="simple-icon-note"></em>
                                    </Button>
                                </span>
                            )
                        },
                    ],
                    dataLoader: false,
                });
            });
    }

    updatePrayerTime() {
        this.setState({dataLoader: true})
        const postApi = {
            id: this.state.prayerTimeId,
            date: this.state.prayerTimeDate,
            imsak: this.state.prayerTimeImsak,
            fajr: this.state.prayerTimeFajr,
            sunrise: this.state.prayerTimeSunrise,
            dhuhr: this.state.prayerTimeDhuhr,
            asr: this.state.prayerTimeAsr,
            maghrib: this.state.prayerTimeMaghrib,
            isha: this.state.prayerTimeIsha,
        }
        PostData("/frontCall/updateSalahTiming", postApi, this.state.userToken)
            .then(result => {
                if (result.response) {
                    this.loadAllPrayerTimes()
                    this.setState({
                        OpeAlert: true,
                        OpeAlertMessage: result.response,
                        OpeAlertVariant: 'success',
                    })
                    this.handleClose()
                } else {
                    this.setState({dataLoader: false})
                    if (result.message.date) {
                        this.setState({prayerTimeDateErr: result.message.date});
                    } else {
                        this.setState({prayerTimeDateErr: ""});
                    }
                    if (result.message.imsak) {
                        this.setState({prayerTimeImsakErr: result.message.imsak});
                    } else {
                        this.setState({prayerTimeImsakErr: ""});
                    }
                    if (result.message.fajr) {
                        this.setState({prayerTimeFajrErr: result.message.fajr});
                    } else {
                        this.setState({prayerTimeFajrErr: ""});
                    }
                    if (result.message.sunrise) {
                        this.setState({prayerTimeSunriseErr: result.message.sunrise});
                    } else {
                        this.setState({prayerTimeSunriseErr: ""});
                    }
                    if (result.message.dhuhr) {
                        this.setState({prayerTimeDhuhrErr: result.message.dhuhr});
                    } else {
                        this.setState({prayerTimeDhuhrErr: ""});
                    }
                    if (result.message.asr) {
                        this.setState({prayerTimeAsrErr: result.message.asr});
                    } else {
                        this.setState({prayerTimeAsrErr: ""});
                    }
                    if (result.message.maghrib) {
                        this.setState({prayerTimeMaghribErr: result.message.maghrib});
                    } else {
                        this.setState({prayerTimeMaghribErr: ""});
                    }
                    if (result.message.isha) {
                        this.setState({prayerTimeIshaErr: result.message.isha});
                    } else {
                        this.setState({prayerTimeIshaErr: ""});
                    }
                }
            })
    }

    setSearch(e) {
        const result = this.state.prayerTimesData.filter(prayerTime => {
            return prayerTime.date.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.imsak.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.fajr.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.sunrise.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.dhuhr.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.asr.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.maghrib.toLowerCase().match(e.target.value.toLowerCase()) ||
                prayerTime.isha.toLowerCase().match(e.target.value.toLowerCase());
        });
        this.setState({filteredPrayerTimesData: result});
    }

    onChangeEditPrayerTime(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleClose() {
        this.setState({
            prayerTimeId: '',
            prayerTimeDate: '',
            prayerTimeImsak: '',
            prayerTimeFajr: '',
            prayerTimeSunrise: '',
            prayerTimeDhuhr: '',
            prayerTimeAsr: '',
            prayerTimeMaghrib: '',
            prayerTimeIsha: '',
            showEditPrayerTimesModal: false,
        })
    }

    handleImportModalShow() {
        this.setState({
            importModal: true,
            eventUploadAlert: false,
            eventUploadAlertMessage: '',
            eventUploadAlertVariant: '',
        })
    }

    handleImportModalClose() {
        this.setState({
            importModal: false,
            dataLoader: false,
        })
        this.loadAllPrayerTimes()
    }

    render() {
        document.title = this.props.title;

        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Prayer Times</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Prayer Times</li>
                                </ol>
                            </nav>
                            <Button onClick={this.handleImportModalShow} className="mb-2 ml-2 line-height-1">Import New Prayer Times</Button>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Modal show={this.state.showEditPrayerTimesModal}
                           onHide={this.handleClose}
                           dialogClassName="modal-dialog-centered"
                           backdrop="static"
                           keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Edit Salah Time</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="prayer_time_date">
                                <Form.Label>Date</Form.Label>
                                <Form.Control type="date" name="prayerTimeDate" placeholder="Date"
                                              value={this.state.prayerTimeDate} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_imsak">
                                <Form.Label>Imsak</Form.Label>
                                <Form.Control type="time" name="prayerTimeImsak" placeholder="Imsak"
                                              value={this.state.prayerTimeImsak} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_fajr">
                                <Form.Label>Fajr</Form.Label>
                                <Form.Control type="time" name="prayerTimeFajr" placeholder="Fajr"
                                              value={this.state.prayerTimeFajr} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_sunrise">
                                <Form.Label>Sunrise</Form.Label>
                                <Form.Control type="time" name="prayerTimeSunrise" placeholder="Sunrise"
                                              value={this.state.prayerTimeSunrise} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_dhuhr">
                                <Form.Label>Dhuhr</Form.Label>
                                <Form.Control type="time" name="prayerTimeDhuhr" placeholder="Dhuhr"
                                              value={this.state.prayerTimeDhuhr} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_asr">
                                <Form.Label>Asr</Form.Label>
                                <Form.Control type="time" name="prayerTimeAsr" placeholder="Asr"
                                              value={this.state.prayerTimeAsr} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_maghrib">
                                <Form.Label>Maghrib</Form.Label>
                                <Form.Control type="time" name="prayerTimeMaghrib" placeholder="Maghrib"
                                              value={this.state.prayerTimeMaghrib} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="prayer_time_isha">
                                <Form.Label>Isha</Form.Label>
                                <Form.Control type="time" name="prayerTimeIsha" placeholder="Isha"
                                              value={this.state.prayerTimeIsha} onChange={this.onChangeEditPrayerTime}/>
                                {this.state.prayerTimeDateErr && <Form.Text as={"div"} className="text-danger"> {this.state.prayerTimeDateErr} </Form.Text>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.updatePrayerTime}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <ImportEventSalah closeImport={this.handleImportModalClose} importModal={this.state.importModal} />

                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                                    </Alert>

                                    {this.state.dataLoader ?
                                        <div className="api-data-loader">
                                            <Spinner animation="grow" variant="info"/>
                                        </div> :
                                        <Datatable
                                            columns={this.state.columns}
                                            data={this.state.filteredPrayerTimesData}
                                            pagination
                                            highlightOnHover
                                            subHeader
                                            subHeaderComponent={
                                                <div className="datatable-search-data">
                                                    <input type="text" placeholder="Search..." className="form-control"
                                                           value={this.state.search} onChange={this.setSearch}/>
                                                    <span className="datatable-search-icon">
                                                        <i className="simple-icon-magnifier"></i>
                                                    </span>
                                                </div>
                                            }
                                        />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        )
    }
}

export default PrayerTimes;