import React from "react";
import {Link} from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

class Sidebar extends React.Component {
    render() {
        return (
            <div className="menu">
                <div className="main-menu">
                    <PerfectScrollbar>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/dashboard">
                                    <i className="simple-icon-speedometer"></i> Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to="/users">
                                    <i className="simple-icon-people"></i> Users
                                </Link>
                            </li>
                            <li>
                                <Link to="/prayer_times">
                                    <i className="iconsminds-taj-mahal"></i> Salah Timings
                                </Link>
                            </li>
                            <li>
                                <Link to="/news_updates">
                                    <i className="iconsminds-newspaper"></i> News Updates
                                </Link>
                            </li>
                            <li>
                                <Link to="/donations">
                                    <i className="iconsminds-financial"></i> Donations
                                </Link>
                            </li>
                            <li>
                                <Link to="/donation_causes">
                                    <i className="iconsminds-financial"></i> Donation Causes
                                </Link>
                            </li>
                            <li>
                                <Link to="/events">
                                    <i className="simple-icon-calendar"></i> Events
                                </Link>
                            </li>
                            <li>
                                <Link to="/administrators">
                                    <i className="simple-icon-user-follow"></i> Administrators
                                </Link>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>
        )
    }
}

export default Sidebar;